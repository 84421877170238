
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';


@Component({
    components: {
        SvgIcon,
    },
})
export default class SendRemittanceSuccess extends Vue {

 link : string = ''

    mounted(){
        if (this.isAuthenticated){
            this.link = '/tablero'
        }else this.link = '/inicio-sesion'
    }

    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
     isAuthenticated!: boolean;
}
